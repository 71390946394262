<template>
  <div class="order-box">
    <nav-bar
      :isFixed="true"
      :goBack="goBack"
      :title="$t('兌換記錄')"
      :bgc="'background-color: #f8f8f8;'"
    ></nav-bar>
    <div class="record-box" :class="{ 'top-box': !isMobile() }">
      <template v-if="list.length > 0">
        <div class="record-item" v-for="item in list" :key="item.orderNo">
          <div class="date-no">
            <span style="margin-right: 10px">{{ item.payTime }}</span>
            <span>{{ item.orderNo }}</span>
          </div>
          <div class="goods-info">
            <img
              :src="getPointsImgUrl(item.picturePath) || defaultImage"
              @error="imgOnError($event)"
              alt=""
              class="img"
            />
            <div style="flex: 1">
              <div class="name-box">{{ item.productName }}</div>
              <div class="point-num">
                <div class="point">
                  {{ $t("積分") }}{{ item.productPoints }}
                </div>
                <div class="num">x{{ item.amount }}</div>
              </div>
            </div>
          </div>
          <div class="sum-box">
            <span style="margin-right: 10px"
              >{{ $t("共") }}{{ item.amount }}{{ $t("件商品") }}</span
            >
            <span
              >{{ $t("合計") }}：{{ item.amount * item.productPoints
              }}{{ $t("積分") }}</span
            >
          </div>
          <div class="status-box">
            <div class="status">{{ item.statusString }}</div>
            <div
              class="btn"
              @click.stop="getViewLogistics(item)"
              v-if="item.status == 3"
            >
              {{ $t("查看物流") }}
            </div>
          </div>
        </div>
      </template>
      <template v-else>
        <div class="orderempty">
          <img src="@/assets/image/order/orderempty.png" alt="" />
          <div class="noOrder">{{ $t("空空如也") }}~</div>
        </div>
      </template>
    </div>
  </div>
</template>
<script>
import { queryPointsOrderList } from "@/api/pointsMall";
import { getPointsImgUrl } from "@/utils/util";
export default {
  data() {
    return {
      list: [],
      defaultImage: require("@/assets/image/default.png"),
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    getPointsImgUrl,
    goBack() {
      this.$router.push({ path: "/pointsMall" });
    },
    isMobile() {
      var u = navigator.userAgent;
      const isAndroid =
        (u.indexOf("Android") > -1 || u.indexOf("Adr") > -1) &&
        u.indexOf("MMMMessenger") > -1; //android终端
      const isIos =
        !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/) &&
        u.indexOf("MMMMessenger") > -1; //ios终端
      return isAndroid || isIos;
    },
    imgOnError(e) {
      e.target.src = this.defaultImage;
    },
    async getList() {
       const langEnv = this.$route.query.langEnv;
      const res = await queryPointsOrderList({
        langEnv: langEnv?langEnv:window.localStorage.getItem("langEnv"),
      },langEnv);
      if (res.resultID === 1200) {
        this.list = res.data;
      }
    },
    getViewLogistics(item) {
      this.$router.push({
        path: `/order/logistics/${item.orderNo}`,
        query: {
          type: "1",
        },
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.order-box {
  height: 100vh;
  overflow-y: auto;
  background: #f3f5f7;
  .top-box {
    padding-top: 44px;
  }
  .record-box {
    .record-item {
      margin-bottom: 10px;
      .date-no {
        font-size: 12px;
        line-height: 40px;
        padding-left: 10px;
        padding-right: 10px;
        background: #fff;
        display: flex;
        justify-content: space-between;
      }
      .goods-info {
        display: flex;
        align-items: center;
        column-gap: 10px;
        padding: 5px 10px;
        min-height: 85px;
        .img {
          width: 75px;
          height: 75px;
        }
        .name-box {
          font-size: 13px;
          line-height: 15px;
          word-break: break-all;
          display: -webkit-box;
          -webkit-line-clamp: 2; /* 控制最大行数 */
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .point-num {
          display: flex;
          justify-content: space-between;
          align-items: center;
          .point {
            color: #f23030;
            font-size: 14px;
          }
          .num {
            color: #333;
            font-size: 13px;
          }
        }
      }
      .sum-box {
        text-align: right;
        background: #fff;
        padding: 5px 10px;
        border-bottom: 1px solid #e7e7e7;
      }
      .status-box {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 46px;
        padding: 0 10px;
        font-size: 16px;
        background: #fff;
        .status {
          color: #f15353;
          line-height: 46px;
        }
        .btn {
          border: 1px solid #999;
          line-height: 26px;
          padding: 0 8px;
          color: #051b28;
          border-radius: 6px;
          text-align: center;
        }
      }
    }
  }
  .orderempty {
    padding-top: 200px;
    display: flex;
    justify-content: center;
    align-content: center;
    flex-wrap: wrap;
    flex-direction: column;
    text-align: center;
    color: #1a1a1a;
    img {
      width: 140px;
      height: 140px;
      object-fit: contain;
    }
    .noOrder {
      width: 140px;
    }
  }
}
</style>
